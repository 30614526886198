<template>
  <el-container>
    <div class="w-100">
      <div class="content">
        <el-row class="empty" v-if="!loadingPlans && plans.length < 1">
          <div>
            <img src="@/assets/images/common/empty/fags.png" alt="方案概述" width="400" class="img" />
            <p>
              <span>什么都没有，快来设置方案吧</span>
              <el-button type="primary" round size @click="toPlan()">设置方案</el-button>
            </p>
          </div>
        </el-row>
        <div class="solution-overview" v-loading="loadingPlans" v-else>
          <el-card class="box-card pb-40">
            <div class="yq-form">
              <div class="yq-form-top">
                <div class="f-l">
                  <span class="tag-name">选择方案</span>
                  <el-select
                    v-model="checkedIds"
                    multiple
                    collapse-tags
                    class="w-280"
                    @visible-change="setPlans"
                    :disabled="loading.main"
                    placeholder="请选择方案">
                    <el-option
                      v-for="plan in plans"
                      :key="plan.id"
                      :label="plan.name"
                      :value="plan.id">
                    </el-option>
                  </el-select>
                </div>
                <i class="f-r iconfont icon-cuowu" @click="toBack"></i>
              </div>
            </div>
            <div class="clear"></div>
            <div class="box-shadow"></div>
            <div class="overview-cont">
              <el-row :gutter="20" class="el-row-20 mod-echarts-box">
                <!-- 方案24小时内信息数量 -->
                <el-col :span="12">
                  <el-card
                    id="infoCard"
                    v-loading="loading.info"
                    class="box-card zzsl ov-v">
                    <div class="mod-title"><span>方案24小时内信息数</span>
                      <feature
                        :filter="[]"
                        v-on:downPhoto="downPhoto('infoCard')"
                        :showDown="true" />
                    </div>
                    <!-- 空状态 -->
                    <div v-if="!infoData.length"
                      class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <!--@end 空状态 -->
                    <div v-if="infoData.length"
                      class="mod-list mod-echarts-cont">
                      <ul>
                        <li v-for="(item, index) in infoData"
                          :key="index"
                          @click="handleClick(item)">
                          <a class="mod-list-title1">{{ item.name }}</a>
                          <el-progress :text-inside="true"
                            :stroke-width="14"
                            :percentage="item.percent"
                            :show-text="false"></el-progress>
                          <span class="tag">{{ item.count }}条</span>
                          <span class="tag sx">
                            <i v-if="item.trend === 'up'"
                              class="el-icon-caret-top"></i>
                            <i v-if="item.trend === 'down'"
                              class="el-icon-caret-bottom"></i>
                          </span>
                          <span class="tag">{{ item.percent }}%</span>
                        </li>
                      </ul>
                    </div>
                  </el-card>
                </el-col>
                <!-- 方案24小时内敏感信息数 -->
                <el-col :span="12">
                  <el-card v-loading="loading.sentimentInfo" class="box-card" id="sentimentInfoCard">
                    <div  class="mod-title ov-v">
                      <span>方案24小时内敏感信息数</span>
                      <feature
                        :filter="[]"
                        v-on:downPhoto="downPhoto('sentimentInfoCard')"
                        :showDown="true" />
                    </div>
                    <!-- 空状态 -->
                    <div v-show="!sentimentInfoData.length"
                      class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <!--@end 空状态 -->
                    <div v-show="sentimentInfoData.length"
                      id="sentimentBox"
                      class="mod-echarts-cont"></div>
                  </el-card>
                </el-col>
                <!-- 下面的需要根据方案数量 来做不同的渲染 -->
                <!-- 2 组以上方案 -->
                <template v-if="isMultiPlan">
                  <!-- 方案24小时内影响力指数 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.health" class="box-card jkzs" id="healthCard">
                      <div class="mod-title ov-v">
                        <span>方案24小时内影响力指数</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('healthCard')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-if="!healthData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div class="mar-t-10 mod-echarts-cont" v-if="healthData.length">
                        <ul class="chart_list">
                          <li v-for="(item, index) in healthData"
                            @click="handleClick(item)"
                            :key="index">
                            <div
                              :class="['char_bg', healthColorMap[item.health_level]]">
                              <div class="char">
                                <p><strong>{{ item.percent }}</strong>分</p>
<!--                                <a>{{ item.health_level }}</a>-->
                              </div>
                            </div>
                            <p>{{ item.name }}</p>
                          </li>
                        </ul>
                      </div>
                    </el-card>
                  </el-col>
                  <!-- 方案敏感信息数量变化 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.sentimentChange" class="box-card jkzs" id="sentimentChangeCard">
                      <div class="mod-title ov-v">
                        <span>方案敏感信息数量变化</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('sentimentChangeCard')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-show="!sentimentChangeData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div v-show="sentimentChangeData.length"
                        id="sentimentChangeBox"
                        class="mod-echarts-cont mar-t-10"></div>
                    </el-card>
                  </el-col>
                  <!-- 方案24小时内信息重要占比 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.infoPercent" class="box-card health_box" id="infoPercentCard">
                      <div class="mod-title ov-v">
                        <span>方案24小时内信息重要占比</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('infoPercentCard')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-if="!infoPrecentData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div v-if="infoPrecentData.length"
                        class="health-box mar-t-10 mod-echarts-cont">
                        <div class="chart_list">
                          <div class="item"
                            v-for="(item, index) in infoPrecentData"
                            @click="handleClick(item)"
                            :key="index">
                            <el-progress type="circle"
                              :percentage="item.percent"></el-progress>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-col>
                  <!-- 方案重要信息数量变化 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.informationChange" class="box-card jkzs" id="informationChangeCard">
                      <div class="mod-title ov-v">
                        <span>方案重要信息数量变化</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('informationChangeCard')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-show="!informationChangeData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div v-show="informationChangeData.length"
                        id="informationChangeBox"
                        class="mod-echarts-cont mar-t-10"></div>
                    </el-card>
                  </el-col>
                </template>
                <!-- 2组及以下方案 -->
                <template v-else>
                  <!-- 方案24小时内影响力指数 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.health" class="box-card jkzs" id="healthCard2">
                      <div class="mod-title ov-v">
                        <span>方案24小时内影响力指数</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('healthCard2')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-if="!healthData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div v-if="healthData.length" class="mar-t-10 mod-echarts-cont">
                        <ul class="chart_list" style="height: 100%; justify-content: center;">
                          <li v-for="(item, index) in healthData"
                            @click="handleClick(item)"
                            :key="index">
                            <div
                              :class="['char_bg', healthColorMap[item.health_level]]">
                              <div class="char">
                                <p><strong>{{ item.percent }}</strong>分</p>
                                <a>{{ item.health_level }}</a>
                              </div>
                            </div>
                            <p>{{ item.name }}</p>
                          </li>
                        </ul>
                      </div>
                    </el-card>
                  </el-col>
                  <!-- 方案24小时内信息重要占比 -->
                  <el-col :span="12"
                    class="mar-t-20">
                    <el-card v-loading="loading.infoPercent" class="box-card jkzs" id="infoPercentCard2">
                      <div class="mod-title ov-v">
                        <span>方案24小时内信息重要占比</span>
                        <feature
                          :filter="[]"
                          v-on:downPhoto="downPhoto('infoPercentCard2')"
                          :showDown="true" />
                      </div>
                      <!-- 空状态 -->
                      <div v-if="!infoPrecentData.length"
                        class="echarts_empty">
                        <div class="img"></div>
                      </div>
                      <!--@end 空状态 -->
                      <div id="infoPercent" v-if="infoPrecentData.length"
                        class="health-box mar-t-10 mod-echarts-cont">
                        <ul class="chart_list" style="width: 100%; height: 100%; justify-content: center;">
                          <li v-for="(item, index) in infoPrecentData"
                          @click="handleClick(item)"
                          :key="index">
                            <el-progress type="circle"
                              :percentage="item.percent"></el-progress>
                            <p>{{ item.name }}</p>
                          </li>
                        </ul>
                      </div>
                    </el-card>
                  </el-col>
                </template>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import { healthColorMap } from './constants';
import { parseDateRange, dom2image } from '@/utils/helpers.js';
import feature from '@components/common/feature.vue';
import { globalCreateChart } from "../../../utils/helpers";
import {
  getSummaryPlans,
  setSummaryPlans,
  getInfoData,
  getSentimentInfoData,
  getHealthData,
  getSentimentChangeData,
  getInfoPercentData,
  getInformationChangeData
} from "@/api/plan";
export default {
  name: 'PlanOverview',
  data() {
    return {
      healthColorMap,
      isMultiPlan: false,
      infoData: [],
      sentimentInfoData: [],
      healthData: [],
      sentimentChangeData: [],
      infoPrecentData: [],
      informationChangeData: [],
      value2: [],
      checkedIds: [],
      sourceCheckedIds: [],
      plans: [],
      loadingPlans: false,
      loading: {main: false, info: false, sentimentInfo: false, health: false, sentimentChange: false, infoPercent: false, informationChange: false},
      isDestroyed: false
    };
  },
  components: {
    feature
  },
  created() {},
  mounted() {
    this.getPlans();
  },
  destroyed() {
    this.isDestroyed = true
  },
  methods: {
    downPhoto(id) {
      dom2image(window.document.getElementById(id), id);
    },
    toBack() {
      this.$router.back(-1);
    },
    // tab跳转
    switchTab(name) {
    },
    // 放案 24 小时内信息数量、方案 24 小时内信息健康指数、方案 24 小时内信息重要占比点击事件
    handleClick(item) {
      this.switchTab(item.name);
    },
    // 错误提示
    errorTip(str) {
      this.$message.error(str || '未知异常，请重试！');
    },
    getPlans() {
      this.loadingPlans = true
      getSummaryPlans().then(res => {
        if (res.data.state) {
          const { data } = res.data
          let ids = []
          data.map((item) => {
            if (item.summary_show) {
              ids.push(item.id)
            }
          })
          // 未选择方案 默认选中前6个方案
          if (_.size(ids) === 0 && _.size(data) >= 1) {
            const cIds = []
            data.map((item, index) => {
              if (index <= 5) {
                cIds.push(item.id)
              }
            })
            this.checkedIds = cIds
            this.setPlans(false)
            return false
          } else {
            this.isMultiPlan = _.size(ids) > 2
            this.checkedIds = ids
            this.sourceCheckedIds = ids
            this.plans = data
          }
        } else {
          this.errorTip(res.data.error)
        }
        this.loadingPlans = false
        this.loadData();
      }).catch(err => {
        this.errorTip('获取方案失败')
      })
    },
    setPlans(state) {
      if (!state) {
        if ((_.size(_.difference(this.checkedIds, this.sourceCheckedIds)) === 0) && (_.size(this.sourceCheckedIds) === _.size(this.checkedIds))) {
          return false
        }
        if (_.size(this.checkedIds) === 0 || _.size(this.checkedIds) > 6) {
          this.$message.warning("方案概述方案数量应为1至6个")
          return false
        }
        setSummaryPlans(this.checkedIds).then(res => {
          if (res.data.state) {
            this.$message.success("设置成功")
            this.sourceCheckedIds = JSON.parse(JSON.stringify(this.checkedIds))
            this.isMultiPlan = _.size(this.checkedIds) > 2
            this.$emit('reload');
          } else {
            this.errorTip(res.data.error)
          }
        }).catch(() => {
          this.errorTip('设置方案失败')
        })
      }
    },
    loadData() {
      let modules = ["loadInfoData", "loadSentimentInfoData", "loadHealthData"]
      // 如果是 2 组以上的方案就请求： 方案敏感信息数量变化、方案重要信息变化
      if (this.isMultiPlan) {
        modules.push("loadSentimentChangeData")
      }
      modules.push("loadInfoPercentData")
      // 如果是 2 组以上的方案就请求: 方案重要信息变化
      if (this.isMultiPlan) {
        modules.push("loadInformationChangeData")
      }
      let promiseQueue = []
      let _this = this
      _(modules).forEach((module) => {
        promiseQueue.push(() => {
          return new Promise((resolve, reject) => {
            _this[module](resolve)
          })
        })
      })
      async function queue(arr) {
        let res = null
        for (let promise of arr) {
          if (_this.isDestroyed) {
            return false
          }
          res = await promise(res)
        }
        return await res
      }
      // promise 队列
      _this.loading.main = true
      queue(promiseQueue).then(data => {
        _this.loading.main = false
      })
    },
    // 方案 24小时信息数量
    loadInfoData(resolve = null) {
      this.loading.info = true;
      getInfoData().then(res => {
          const { state, error, data } = res.data;
          if (state) {
            this.infoData = data;
          } else {
            this.errorTip(error);
          }
          this.loading.info = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.info = false;
          if (resolve) return resolve()
        });
    },
    // 方案 24小时敏感信息数量
    loadSentimentInfoData(resolve = null) {
      this.loading.sentimentInfo = true;
      getSentimentInfoData()
        .then(res => {
          const { state, error, data } = res.data;
          if (state) {
            const formatData = [];
            data.map(item => {
              const { name, pos_num, neg_num } = item;
              const obj = {
                name,
              };
              if (pos_num > 0) {
                obj['非敏感数据'] = pos_num;
              }
              if (neg_num > 0) {
                obj['敏感数据'] = neg_num;
              }
              formatData.push({
                name,
                '非敏感数据': pos_num, // 数据为 0 的时候， yAxis.type = 'log'错误，但是不设置为 log 图表数据差距过大 小的数据量就不显示
                '敏感数据': neg_num,
              });
            });
            this.sentimentInfoData = data;
            const option = {
              legend: {
                icon: 'circle',
                itemWidth:10,
                itemHeight:10,
                itemGap:20,
                top:"20px"
              },
              grid: {
                left: '0',
                right: '0',
                top: '60px',
                containLabel: true,
                bottom:"20px"
              },
              tooltip: {
                // trigger: 'axis',
              },
              dataset: {
                dimensions: ['name', '非敏感数据', '敏感数据'],
                source: formatData,
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                axisLabel: {
                  interval: 0,
                  rotate: formatData.length > 4 ? 30 : 0,
                },
              },
              color: ['#555DFE', '#FC5D73'],
              yAxis: {
                min: 1,
                axisLabel: {
                  interval: 0,
                  margin: 2,
                  formatter: function(value, index) {
                    if (value >= 10000 && value < 10000000) {
                      value = value / 10000 + '万';
                    } else if (value >= 10000000) {
                      value = value / 10000000 + '千万';
                    }
                    return value;
                  },
                },
              },
              series: [{ type: 'bar' }, { type: 'bar' }],
            };
            this.$nextTick(() => {
              const ele = document.getElementById('sentimentBox');
              if (!ele) return false
              // $(ele).css('width', $('.ercharts-box').width());
              const chartDom = globalCreateChart(ele, option);
              chartDom.on('click', (params) => {
                // 根据名字找到对应的 ID
                const { name } = params;
                this.switchTab(name);
              })
            });
          } else {
            this.errorTip(error);
          }
          this.loading.sentimentInfo = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.sentimentInfo = false;
          if (resolve) return resolve()
        });
    },
    // 方案 24小时健康指数
    loadHealthData(resolve = null) {
      this.loading.health = true;
      getHealthData()
        .then(res => {
          const { state, error, data } = res.data;
          if (state) {
            this.healthData = data;
          } else {
            this.errorTip(error);
          }
          this.loading.health = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.health = false;
          if (resolve) return resolve()
        });
    },
    // 方案 敏感信息数量变化
    loadSentimentChangeData(resolve = null) {
      this.loading.sentimentChange = true;
      getSentimentChangeData()
        .then(res => {
          const { state, error, data } = res.data;
          if (state) {
            this.sentimentChangeData = data.data;
            const { prev, next } = data;
            const indicator = [];
            const value1 = [];
            const value2 = [];
            data.data.map(item => {
              const { name, prev_num, next_num } = item;
              indicator.push({
                name: item.name,
                max:
                  next_num > prev_num
                    ? Math.ceil(next_num * 1.5)
                    : Math.ceil(prev_num * 1.5),
              });
              value1.push(prev_num);
              value2.push(next_num);
            });
            const option = {
              title: {},
              tooltip: {},
              legend: {
                data: [prev, next],
                icon: 'circle',
                itemWidth:10,
                itemHeight:10,
                itemGap:5,
                top:"10px",
                orient:"vertical"
              },
              color: ['#ffa4ac', '#e74151'],
              radar: {
                // shape: 'circle',
                nameGap: 3,
                center: ['50%', '60%'],
                radius: 80,
                name: {
                  textStyle: {
                    color: '#000',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    padding: [3, 3],
                  },
                },
                indicator,
              },
              series: [
                {
                  name: '',
                  type: 'radar',
                  areaStyle: { normal: {} },
                  tooltip: {
                    position: ['40%', '38%'],
                    textStyle: {
                      fontSize: 14
                    }
                  },
                  data: [
                    {
                      value: [...value1],
                      name: prev,
                    },
                    {
                      value: [...value2],
                      name: next,
                    },
                  ],
                },
              ],
            };
            this.$nextTick(() => {
              const ele = document.getElementById('sentimentChangeBox');
              if (!ele) return false
              // $(ele).css('width', $('#sentimentChangeBox').width());
              const chartDom = globalCreateChart(ele, option);
              chartDom.on('click', (params) => {
                // 根据名字找到对应的 ID
                // const { name } = params;
                // this.switchTab(name);
              })
            })
          } else {
            this.errorTip(error);
          }
          this.loading.sentimentChange = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.sentimentChange = false;
          if (resolve) return resolve()
        });
    },
    // 方案 信息重要占比
    loadInfoPercentData(resolve = null) {
      this.loading.infoPercent = true;
      getInfoPercentData()
        .then(res => {
          const { state, error, data } = res.data;
          if (state) {
            this.infoPrecentData = data;
          } else {
            this.errorTip(error);
          }
          this.loading.infoPercent = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.infoPercent = false;
          if (resolve) return resolve()
        });
    },
    // 方案 重要信息数量变化
    loadInformationChangeData(resolve = null) {
      this.loading.informationChange = true;
      getInformationChangeData()
        .then(res => {
          const { state, error, data } = res.data;
          if (state) {
            this.informationChangeData = data.data;
            const { prev, next } = data;
            const indicator = [];
            const value1 = [];
            const value2 = [];
            data.data.map(item => {
              const { name, prev_num, next_num } = item;
              indicator.push({
                name: item.name,
                max:
                  next_num > prev_num
                    ? Math.ceil(next_num * 1.5)
                    : Math.ceil(prev_num * 1.5),
              });
              value1.push(prev_num);
              value2.push(next_num);
            });
            const option = {
              title: {},
              tooltip: {},
              legend: {
                data: [prev, next],
                icon: 'circle',
                itemWidth:10,
                itemHeight:10,
                itemGap:20,
                top:"10px"
              },
              color: ['#87bdff', '#f0a142'],
              radar: {
                // shape: 'circle',
                nameGap: 3,
                center: ['50%', '60%'],
                radius: 80,
                name: {
                  textStyle: {
                    color: '#000',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    padding: [3, 3],
                  },
                },
                indicator,
              },
              series: [
                {
                  name: '',
                  type: 'radar',
                  areaStyle: { normal: {} },
                  tooltip: {
                    position: ['40%', '38%'],
                    textStyle: {
                      fontSize: 14,
                    }
                  },
                  data: [
                    {
                      value: [...value1],
                      name: prev,
                    },
                    {
                      value: [...value2],
                      name: next,
                    },
                  ],
                },
              ],
            };
            this.$nextTick(() => {
              const ele = document.getElementById('informationChangeBox');
              if (!ele) return false
              // $(ele).css('width', $('#informationChangeBox').width());
              const chartDom = globalCreateChart(ele, option);
              chartDom.on('click', (params) => {
                // 根据名字找到对应的 ID
                // const { name } = params;
                // this.switchTab(name);
              })
            })
          } else {
            this.errorTip(error);
          }
          this.loading.informationChange = false;
          if (resolve) return resolve()
        })
        .catch(error => {
          this.errorTip(error || '未知异常，请重试！');
          this.loading.informationChange = false;
          if (resolve) return resolve()
        });
    },
    toPlan() {
      this.$router.push({path: '/yq'});
    },
  },
};
</script>
<style lang="css" scoped>
.yq-form{font-size: 14px;}
.solution-overview .mod-echarts-cont,.solution-overview .{height: 300px;}
.yq-form-top .tag-name{margin-right:20px;}
.solution-overview >>> .el-card__body{padding:20px 40px;}
.solution-overview .box-shadow{margin:30px auto;}
.mod-echarts-box >>> .el-card__body{padding:20px;}
.mod-echarts-box .mod-title{margin-left: 0;}
/*方案24小时内信息数量*/
.zzsl .mod-list li {width: 100%;line-height:45px;height:45px;}
.zzsl .mod-list li a {width: 116px;}
.zzsl .mod-list >>> .el-progress {width: 49%;float: left;margin-top: 12px;}
.zzsl .sx {width: 40px;margin-top: 3px;}
.zzsl .sx .el-icon-caret-bottom:before {color: #3338ff;font-size: 24px;}
.zzsl .sx .el-icon-caret-top:before {color: #ff1616;font-size: 24px;}
.zzsl .mod-list ul {justify-content: center;align-items: center;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;flex-direction: column;height:300px;overflow: hidden;}
/*方案24小时内影响力指数*/
.jkzs .mod-echarts-cont,.health_box .mod-echarts-cont{display: flex;justify-content: center;align-content: center;}
.jkzs .chart_list {display: flex;/* justify-content: space-evenly; */
align-content: space-evenly;flex-wrap: wrap;width: 100%;}
.chart_list li {width: 33%;margin-top:8px;text-align: center;}
.chart_list .char_bg {width:100px;height:100px;border-radius: 50%;overflow: hidden;margin: 0 auto;}
.chart_list .char {width:90px;height:90px;text-align: center;border-radius: 50%;margin: 5px auto;color: #fff;font-size: 12px;}
.chart_list .char p {line-height: 26px;overflow: hidden;padding-top: 30px;color:#fff;}
.chart_list .char p strong {font-size: 18px;font-weight:400;color:#fff;}
.chart_list .char a {width: 59px;text-align: center;line-height: 16px;height: 16px;border-radius: 8px;display: inline-block;margin: 0 auto;color: #fff;}
.chart_list p{font-size: 12px;text-align: center;line-height: 37px;}
.chart_list .green {background: rgba(110, 221, 147, 0.2);}
.chart_list .green .char {background: linear-gradient(180deg,rgba(255, 255, 255, 1) 0%,rgba(91, 200, 127, 1) 0%,rgba(115, 228, 152, 1) 100%);}
.chart_list .green .char a {background: rgba(73, 189, 112, 1);}
.chart_list .orange {background: rgba(241, 172, 96, 0.2);}
.chart_list .orange .char {background: linear-gradient(0deg,rgba(91, 200, 127, 1) 0%,rgba(249, 183, 109, 1) 0%,rgba(239, 135, 56, 1) 100%);}
.chart_list .orange .char a {background: #ea893d;}
.chart_list .red {background: rgba(255, 94, 96, 0.2);}
.chart_list .red .char {background: linear-gradient(180deg,rgba(91, 200, 127, 1) 0%,rgba(255, 61, 61, 1) 0%,rgba(251, 121, 120, 1) 100%);}.chart_list .red .char a {background: #eb4848;}.list_box >>> .el-card__body{height: 0;}
.fags_empty >>> .el-card__body{height: auto;}
.ercharts-box {width: calc(100% - 40px);height: 300px;}
.ercharts-box2 {width: calc(100% - 40px);height: 280px;}
.health-box .chart_list{display: flex;flex-wrap: wrap;width: 100%;}
.health-box .item {flex-basis: 33%;margin-top: 20px;text-align: center;}
.health-box p{line-height: 30px;text-align: center;font-size: 12px;}
.health-box >>> .el-progress-circle {width: 90px !important;height: 90px !important;}
</style>
